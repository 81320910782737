@import "colors";

.longDescriptionPopper {

    .popper {
        z-index: 999999999;
        width: 600px;
        padding: 22px;
        height: 570px; } }

.row {
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px; }

.editRow {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.icon {
    width: 40px;
    height: 40px;
    border-radius: 28px;
    background-color: $sideNav-dk-orange;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-right: 16px; }

.icon  img {
    width: 100%;
    height: 100%; }

.headingText {
    margin-bottom: 15px;
    display: flex;
    align-items: center; }

.headingText p {
    margin: 0;
    font-size: 22px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: 100;
    color: $sharePopper-dk-grey; }

.descriptionLabelText {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 16px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: bold;
    color: $sharePopper-dk-grey;
    letter-spacing: 0.18px;
    margin-bottom: 10px; }

.doneBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 10px 38px;
    margin-right: -15px; }

.descriptionText {
    font-family: "Mulish", sans-serif;
    color: $sharePopper-dk-grey;
    font-size: 14px;
    font-style: normal;
    height: 350px;
    resize: none;
    white-space: pre-line;
    scroll-behavior: smooth;
    overflow: scroll; }

.editIcon {
    cursor: pointer;
    width: 36px;
    height: 36px; }

.editIcon:hover {
    background-color: #ffe4e1;
    border-radius: 18px; }
